/* eslint-disable camelcase */
import { Trans, t } from '@lingui/macro';
import { formatMonetaryValue, isPresent } from '@luminovo/commons';
import {
    FieldSelect,
    Flexbox,
    Link,
    PrimaryButton,
    SecondaryButton,
    Text,
    chainComparators,
    colorSystem,
    compareByNumber,
} from '@luminovo/design-system';
import {
    AvailabilityType,
    FullPart,
    IpnWithMatchesFullPart,
    OtsFullPart,
    TechnicalProperties,
    isOtsComponentFull,
} from '@luminovo/http-client';
import {
    ComplianceStatusChip,
    LifecycleChip,
    LifecycleTooltip,
    formatSupplierAndStockLocationDTO,
} from '@luminovo/sourcing-core';
import { DescriptionOutlined, Info, InfoOutlined } from '@mui/icons-material';
import { Skeleton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
    MarketOfferTableData,
    extractStock,
    extractUnitPrice,
    useMarketOfferTableItems,
} from '../../../modules/PartLibrary/PartDetailsPage/components/OffersTab/MarketOfferTable';
import { BomItem } from '../../../resources/designItem/bomItemFrontendTypes';
import { calculateRequiredStock } from '../../../resources/sourcingScenario/calculateRequiredStock';
import { useSourcingScenariosOfRfq } from '../../../resources/sourcingScenario/sourcingScenarioHandlers';
import {
    formatCapacitance,
    formatDielectricMaterial,
    formatPowerRating,
    formatResistance,
    formatTemperatureCoefficient,
    formatTolerance,
    formatVoltageRating,
} from '../../../utils/converterUtils';
import { route } from '../../../utils/routes';
import { PartAvailabilityLabel } from '../../PartAvailabilityView/PartAvailabilityLabel';
import { usePartAvailability } from '../../PartAvailabilityView/usePartAvailability';
import { OtsPartComplianceStatusOriginsTooltip } from '../../PartComplianceView/OtsPartComplianceStatusOriginsTooltip';
import { EmissionsTooltip } from '../../PartEmissionsView/EmissionsTooltip';
import { PartEmissionsView } from '../../PartEmissionsView/PartEmissionsView';
import { OtsPartLifecycleStatusOriginsTooltip } from '../../PartLifecycleView';
import { MAX_COLUMN_WIDTH } from '../CompareItemsDialog';
import { PartImage } from '../commons/PartImage';
import { findComparableOtsPart } from '../commons/findComparableOtsPart';
import { Attribute } from '../types';
import { PartSearchComparisonContext } from './types';

enum AttributesIndex {
    PartImage = 'partImage',
    MpnManufacturer = 'mpnManufacturer',
    Description = 'description',
    Datasheet = 'datasheet',
    PartType = 'partType',
    SourcingHeader = 'sourcingHeader',
    Availability = 'availability',
    BestPriceInStock = 'bestPriceInStock',
    BestPrice = 'bestPrice',
    SpecificationsHeader = 'specificationsHeader',
    Resistance = 'resistance',
    Capacitance = 'capacitance',
    Tolerance = 'tolerance',
    PowerRating = 'powerRating',
    Dielectric = 'dielectric',
    VoltageRating = 'voltageRating',
    TempCoefficient = 'tempCoefficient',
    PackageHeader = 'packageHeader',
    PackageName = 'packageName',
    Mounting = 'mounting',
    NumberOfPins = 'numberOfPins',
    ComplianceHeader = 'complianceHeader',
    RohsCompliant = 'rohsCompliant',
    ReachCompliant = 'reachCompliant',
    AecqCompliant = 'aecqCompliant',
    LifecycleHeader = 'lifecycleHeader',
    Lifecycle = 'lifecycle',
    LifecycleYteol = 'lifecycleYteol',
    SustainabilityHeader = 'sustainabilityHeader',
    Emissions = 'emissions',
}

type PartAttribute = Attribute<OtsFullPart | IpnWithMatchesFullPart, OtsFullPart, PartSearchComparisonContext>;

const RenderImageAttribute = ({
    part,
    context,
    isColumnHovered,
}: {
    part: OtsFullPart;
    context: PartSearchComparisonContext;
    isColumnHovered: boolean;
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { handleAddPartOption, tableState } = context;
    const { dispatch } = tableState;

    return (
        <Flexbox justifyContent="center" style={{ position: 'relative' }}>
            <PartImage imageUrl={part.image_url} isHovered={isColumnHovered} />
            <PrimaryButton
                size="small"
                onClick={() => {
                    handleAddPartOption(part, 'comparePartsModal');
                    dispatch({ type: 'select-items', ids: [part.id], selected: false });
                    enqueueSnackbar(part.mpn + ', ' + part.manufacturer.name + ' ' + t`approved`, {
                        variant: 'success',
                    });
                }}
                style={{
                    margin: 'auto',
                    width: 'auto',
                    display: isColumnHovered ? 'block' : 'none',
                    position: 'absolute',
                    top: '45px',
                }}
            >
                <Trans>Approve part</Trans>
            </PrimaryButton>
            <SecondaryButton
                size="small"
                onClick={() =>
                    window.open(route('/parts/details/:partId', { partId: part.id }), '_blank', 'noopener noreferrer')
                }
                style={{
                    margin: 'auto',
                    width: 'auto',
                    display: isColumnHovered ? 'block' : 'none',
                    position: 'absolute',
                    top: '75px',
                }}
            >
                <Trans>Open part page</Trans>
            </SecondaryButton>
        </Flexbox>
    );
};

const attributeImage: PartAttribute = {
    id: AttributesIndex.PartImage,
    label: '',
    isVisible: () => true,
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        return (
            <Flexbox justifyContent="center">
                <PartImage imageUrl={otsPart.image_url} showBackground={false} />
            </Flexbox>
        );
    },
    renderComparableItem: (part, context) => {
        return <RenderImageAttribute context={context} isColumnHovered={false} part={part} />;
    },
    renderComparableItemOnHover: (part, context) => {
        return <RenderImageAttribute context={context} isColumnHovered={true} part={part} />;
    },
};

const renderOption = (part: OtsFullPart | IpnWithMatchesFullPart) => {
    const otsPart = findComparableOtsPart(part);
    if (!otsPart) {
        return undefined;
    }
    if (isOtsComponentFull(part)) {
        return (
            <Flexbox justifyContent="space-between" width={'100%'}>
                <span>{otsPart.mpn + ', ' + otsPart.manufacturer.name}</span>
                <span style={{ color: colorSystem.neutral[6] }}>{'IPN ' + part.id}</span>
            </Flexbox>
        );
    }
    return otsPart.mpn + ', ' + otsPart.manufacturer.name;
};

const renderLabel = (part: OtsFullPart | IpnWithMatchesFullPart) => {
    const otsPart = findComparableOtsPart(part);
    if (!otsPart) {
        return '';
    }
    return otsPart.mpn + ', ' + otsPart.manufacturer.name;
};

const attributeMpnManufacturer: PartAttribute = {
    id: AttributesIndex.MpnManufacturer,
    label: <Trans>MPN, manufacturer</Trans>,
    isVisible: () => true,
    renderItem: ({ data: selectedPart, dispatch }, sharedContext) => {
        const { partOptions } = sharedContext;
        if (partOptions.length === 1) {
            return <Text variant="h4">{renderLabel(selectedPart)}</Text>;
        }
        return (
            <FieldSelect
                value={selectedPart}
                options={partOptions}
                onChange={(value) => dispatch({ type: 'setSelectedItem', selectedItem: value ?? selectedPart })}
                renderOption={renderOption}
                getOptionLabel={renderLabel}
                size="small"
                disableClearable
                style={{ background: 'white', color: colorSystem.neutral[7] }}
            />
        );
    },
    renderComparableItem: (data) => {
        return <Text variant="h4">{renderLabel(data)}</Text>;
    },
};

const attributeDescription: PartAttribute = {
    id: AttributesIndex.Description,
    label: <Trans>Description</Trans>,
    isVisible: () => true,
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        return (
            <Flexbox style={{ maxWidth: MAX_COLUMN_WIDTH }}>
                <Text showEllipsis>{otsPart.description ?? '-'}</Text>
            </Flexbox>
        );
    },
    renderComparableItem: ({ description }) => {
        return (
            <Flexbox style={{ maxWidth: MAX_COLUMN_WIDTH }}>
                <Text showEllipsis>{description ?? '-'}</Text>
            </Flexbox>
        );
    },
};

const DatasheetLink = ({ url }: { url: string | null | undefined }) => {
    return (
        <Link
            href={url ?? undefined}
            attention="high"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<DescriptionOutlined />}
        >
            <Trans>Datasheet</Trans>
        </Link>
    );
};

const attributeDatasheet: PartAttribute = {
    id: AttributesIndex.Datasheet,
    label: <Trans>Datasheet</Trans>,
    isVisible: () => true,
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        return <DatasheetLink url={otsPart.datasheet_url} />;
    },
    renderComparableItem: ({ datasheet_url }) => {
        return <DatasheetLink url={datasheet_url} />;
    },
};

function isResistor({ partType }: { partType: string | null | undefined }): boolean {
    return partType?.toLocaleLowerCase().includes('resistor') ?? false;
}

function isCapacitor({ partType }: { partType: string | null | undefined }): boolean {
    return partType?.toLocaleLowerCase().includes('capacitor') ?? false;
}

const attributePartType: PartAttribute = {
    id: AttributesIndex.PartType,
    label: <Trans>Type</Trans>,
    isVisible: ({ data: part, comparableData: searchedParts, dialogState }) => {
        if (dialogState.viewMode === 'show-all') {
            return true;
        }
        const otsPart = findComparableOtsPart(part);
        return !searchedParts.every((p) => {
            return (
                p.type === otsPart.type ||
                (isCapacitor({ partType: p.type }) && isCapacitor({ partType: otsPart.type })) ||
                (isResistor({ partType: p.type }) && isResistor({ partType: otsPart.type }))
            );
        });
    },
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        return <>{otsPart.type ?? '-'}</>;
    },
    renderComparableItem: ({ type }) => {
        return <>{type ?? '-'}</>;
    },
};

const sourcingHeader: PartAttribute = {
    id: AttributesIndex.SourcingHeader,
    label: (
        <Text variant="h4">
            <Trans>Sourcing</Trans>
        </Text>
    ),
    isVisible: () => true,
    renderItem: () => {
        return <></>;
    },
    renderComparableItem: () => {
        return <></>;
    },
};

const AvailabilityStatus = ({
    bomItem,
    part,
    rfqId,
    assemblyId,
}: {
    bomItem: BomItem;
    part: FullPart;
    rfqId: string;
    assemblyId: string;
}) => {
    const availability = usePartAvailability({ bomItem, part, rfqId, assemblyId });
    if (!availability) {
        return null;
    }
    return <PartAvailabilityLabel availability={availability} part={part} />;
};

const attributeAvailability: PartAttribute = {
    id: AttributesIndex.Availability,
    label: <Trans>Availability</Trans>,
    isVisible: () => true,
    renderItem: ({ data: part }, sharedContext) => {
        const { bomItem, rfqId, assemblyId } = sharedContext;
        return <AvailabilityStatus assemblyId={assemblyId} part={part} bomItem={bomItem} rfqId={rfqId} />;
    },
    renderComparableItem: (data, context) => {
        const { bomItem, rfqId, assemblyId } = context;
        return <AvailabilityStatus assemblyId={assemblyId} part={data} bomItem={bomItem} rfqId={rfqId} />;
    },
};

const BestPriceInStockInfo = ({ part, rfqId, bomItem }: { part: FullPart; rfqId: string; bomItem: BomItem }) => {
    const { data: sourcingScenarios = [] } = useSourcingScenariosOfRfq(rfqId);
    const { maxRequiredStock } = calculateRequiredStock({ sourcingScenarios, bomItem });

    const { data: tableItems } = useMarketOfferTableItems({
        marketOfferPart: { type: 'OffTheShelf', id: part.id },
        requiredQuantity: maxRequiredStock,
        rfqContext: { type: 'WithinRfQ', rfq_id: rfqId },
    });

    if (!isPresent(tableItems)) {
        return <Skeleton width={'80px'} />;
    }

    const filteredTableItems = tableItems
        .filter((item) => item.isApproved || item.isPreferred)
        .filter((item) => item.requiredQuantitySolution?.availability?.type === AvailabilityType.Stock);

    if (filteredTableItems.length === 0) {
        return <Trans>No offers found</Trans>;
    }

    return (
        <Tooltip title={`Supplier: ${formatSupplierAndStockLocationDTO(filteredTableItems[0].supplier)}`}>
            <span>{formatMonetaryValue(filteredTableItems[0].unitPrice, 'unit-price')}</span>
        </Tooltip>
    );
};

const attributeBestPriceInStock: PartAttribute = {
    id: AttributesIndex.BestPriceInStock,
    label: (
        <Flexbox alignItems="center" gap="4px">
            <Trans>Best price in stock</Trans>
            <Tooltip
                title={
                    <Trans>
                        Best price in stock regardless of price breaks across all offers from your preferred and
                        approved suppliers.
                    </Trans>
                }
                placement="bottom"
                arrow
            >
                <InfoOutlined style={{ color: colorSystem.neutral[6], height: 16 }} />
            </Tooltip>
        </Flexbox>
    ),
    isVisible: () => true,
    renderItem: ({ data: part }, { rfqId, bomItem }) => {
        return <BestPriceInStockInfo part={part} rfqId={rfqId} bomItem={bomItem} />;
    },
    renderComparableItem: (data, context) => {
        return <BestPriceInStockInfo part={data} rfqId={context.rfqId} bomItem={context.bomItem} />;
    },
};

const BestPriceInfo = ({ part, rfqId, bomItem }: { part: FullPart; rfqId: string; bomItem: BomItem }) => {
    const { data: sourcingScenarios = [] } = useSourcingScenariosOfRfq(rfqId);
    const { maxRequiredStock } = calculateRequiredStock({ sourcingScenarios, bomItem });

    const { data: tableItems } = useMarketOfferTableItems({
        marketOfferPart: { type: 'OffTheShelf', id: part.id },
        requiredQuantity: maxRequiredStock,
        rfqContext: { type: 'WithinRfQ', rfq_id: rfqId },
    });

    if (!isPresent(tableItems)) {
        return <Skeleton width={'80px'} />;
    }

    const filteredtableItems = tableItems.filter((item) => item.isApproved || item.isPreferred);

    if (filteredtableItems.length === 0) {
        return <Trans>No offers found</Trans>;
    }

    const sortedItems = filteredtableItems.sort(
        chainComparators(
            compareByNumber((x: MarketOfferTableData) => extractUnitPrice(x)),
            compareByNumber((x: MarketOfferTableData) => -extractStock(x)),
            (a, b) => a.offer.id.localeCompare(b.offer.id),
        ),
    );
    return (
        <Tooltip title={`Supplier: ${formatSupplierAndStockLocationDTO(sortedItems[0].supplier)}`}>
            <span>{formatMonetaryValue(sortedItems[0].unitPrice, 'unit-price')}</span>
        </Tooltip>
    );
};

const attributeBestPrice: PartAttribute = {
    id: AttributesIndex.BestPrice,
    label: (
        <Flexbox alignItems="center" gap="4px">
            <Trans>Best price</Trans>
            <Tooltip
                title={
                    <Trans>
                        Best price regardless of price breaks across all offers from your preferred and approved
                        suppliers.
                    </Trans>
                }
                placement="bottom"
                arrow
            >
                <InfoOutlined style={{ color: colorSystem.neutral[6], height: 16 }} />
            </Tooltip>
        </Flexbox>
    ),
    isVisible: () => true,
    renderItem: ({ data: part }, { rfqId, bomItem }) => {
        return <BestPriceInfo part={part} rfqId={rfqId} bomItem={bomItem} />;
    },
    renderComparableItem: (data, context) => {
        return <BestPriceInfo part={data} rfqId={context.rfqId} bomItem={context.bomItem} />;
    },
};

function areResistorSpecsSimilar({
    comparableParts,
    selectedPartSpecs,
}: {
    comparableParts: OtsFullPart[];
    selectedPartSpecs: TechnicalProperties | null;
}): boolean {
    const resistance = selectedPartSpecs?.resistance ? formatResistance(selectedPartSpecs?.resistance) : '-';
    const tolerance = selectedPartSpecs?.tolerance ? formatTolerance(selectedPartSpecs?.tolerance) : '-';
    const powerRating = selectedPartSpecs?.power_rating ? formatPowerRating(selectedPartSpecs?.power_rating) : '-';
    const voltageRating = selectedPartSpecs?.voltage_rating
        ? formatVoltageRating(selectedPartSpecs?.voltage_rating)
        : '-';
    const temperatureCoefficient = selectedPartSpecs?.temperature_coefficient
        ? formatTemperatureCoefficient(selectedPartSpecs?.temperature_coefficient)
        : '-';

    return !comparableParts.every((part) => {
        const comparableResistance = part.technical_properties?.resistance
            ? formatResistance(part.technical_properties?.resistance)
            : '-';
        const comparableTolerance = part.technical_properties?.tolerance
            ? formatTolerance(part.technical_properties?.tolerance)
            : '-';
        const comparablePowerRating = part.technical_properties?.power_rating
            ? formatPowerRating(part.technical_properties?.power_rating)
            : '-';
        const comparableVoltageRating = part.technical_properties?.voltage_rating
            ? formatVoltageRating(part.technical_properties?.voltage_rating)
            : '-';
        const comparableTemperatureCoefficient = part.technical_properties?.temperature_coefficient
            ? formatTemperatureCoefficient(part.technical_properties?.temperature_coefficient)
            : '-';
        return (
            resistance === comparableResistance &&
            tolerance === comparableTolerance &&
            powerRating === comparablePowerRating &&
            voltageRating === comparableVoltageRating &&
            temperatureCoefficient === comparableTemperatureCoefficient
        );
    });
}

function areCapacitorSpecsSimilar({
    comparableParts,
    selectedPartSpecs,
}: {
    comparableParts: OtsFullPart[];
    selectedPartSpecs: TechnicalProperties | null;
}): boolean {
    const capacitance = selectedPartSpecs?.capacitance ? formatCapacitance(selectedPartSpecs?.capacitance) : '-';
    const tolerance = selectedPartSpecs?.tolerance ? formatTolerance(selectedPartSpecs?.tolerance) : '-';
    const dielectric = selectedPartSpecs?.dielectric ? formatDielectricMaterial(selectedPartSpecs?.dielectric) : '-';
    const voltageRating = selectedPartSpecs?.voltage_rating
        ? formatVoltageRating(selectedPartSpecs?.voltage_rating)
        : '-';

    return !comparableParts.every((part) => {
        const comparableCapacitance = part.technical_properties?.capacitance
            ? formatCapacitance(part.technical_properties?.capacitance)
            : '-';
        const comparableTolerance = part.technical_properties?.tolerance
            ? formatTolerance(part.technical_properties?.tolerance)
            : '-';
        const comparableDielectric = part.technical_properties?.dielectric
            ? formatDielectricMaterial(part.technical_properties?.dielectric)
            : '-';
        const comparableVoltageRating = part.technical_properties?.voltage_rating
            ? formatVoltageRating(part.technical_properties?.voltage_rating)
            : '-';

        return (
            capacitance === comparableCapacitance &&
            tolerance === comparableTolerance &&
            dielectric === comparableDielectric &&
            voltageRating === comparableVoltageRating
        );
    });
}

const specificationsHeader: PartAttribute = {
    id: AttributesIndex.SpecificationsHeader,
    label: (
        <Text variant="h4">
            <Trans>Specifications</Trans>
        </Text>
    ),
    isVisible: ({ data: part, comparableData: searchedParts, dialogState }) => {
        const otsPart = findComparableOtsPart(part);

        if (
            dialogState.viewMode === 'show-all' &&
            (isResistor({ partType: otsPart.type }) || isCapacitor({ partType: otsPart.type }))
        ) {
            return true;
        }
        if (isResistor({ partType: otsPart.type })) {
            return areResistorSpecsSimilar({
                comparableParts: searchedParts,
                selectedPartSpecs: otsPart.technical_properties,
            });
        }
        if (isCapacitor({ partType: otsPart.type })) {
            return areCapacitorSpecsSimilar({
                comparableParts: searchedParts,
                selectedPartSpecs: otsPart.technical_properties,
            });
        }
        return false;
    },
    renderItem: () => {
        return <></>;
    },
    renderComparableItem: () => {
        return <></>;
    },
};

const attributeResistance: PartAttribute = {
    id: AttributesIndex.Resistance,
    label: <Trans>Resistance</Trans>,
    isVisible: ({ data: part, comparableData: searchedParts, dialogState }) => {
        const otsPart = findComparableOtsPart(part);
        if (dialogState.viewMode === 'show-all' && isResistor({ partType: otsPart.type })) {
            return true;
        }
        const selectedPartResistance = otsPart.technical_properties?.resistance
            ? formatResistance(otsPart.technical_properties?.resistance)
            : '-';

        return (
            isResistor({ partType: otsPart.type }) &&
            !searchedParts.every((part) => {
                const comparableResistance = part.technical_properties?.resistance
                    ? formatResistance(part.technical_properties?.resistance)
                    : '-';
                return selectedPartResistance === comparableResistance;
            })
        );
    },
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        const resistance = otsPart.technical_properties?.resistance;
        return <>{resistance ? formatResistance(resistance) : '-'}</>;
    },
    renderComparableItem: ({ technical_properties }) => {
        const resistance = technical_properties?.resistance;
        return <>{resistance ? formatResistance(resistance) : '-'}</>;
    },
};

const attributeCapacitance: PartAttribute = {
    id: AttributesIndex.Capacitance,
    label: <Trans>Capacitance</Trans>,
    isVisible: ({ data: part, comparableData: searchedParts, dialogState }) => {
        const otsPart = findComparableOtsPart(part);
        if (dialogState.viewMode === 'show-all' && isCapacitor({ partType: otsPart.type })) {
            return true;
        }
        const selectedPartCapacitance = otsPart.technical_properties?.capacitance
            ? formatCapacitance(otsPart.technical_properties?.capacitance)
            : '-';

        return (
            isCapacitor({ partType: otsPart.type }) &&
            !searchedParts.every((part) => {
                const comparableCapacitance = part.technical_properties?.capacitance
                    ? formatCapacitance(part.technical_properties?.capacitance)
                    : '-';
                return selectedPartCapacitance === comparableCapacitance;
            })
        );
    },
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        const capacitance = otsPart.technical_properties?.capacitance;
        return <>{capacitance ? formatCapacitance(capacitance) : '-'}</>;
    },
    renderComparableItem: (data) => {
        const capacitance = data.technical_properties?.capacitance;
        return <>{capacitance ? formatCapacitance(capacitance) : '-'}</>;
    },
};

const attributeTolerance: PartAttribute = {
    id: AttributesIndex.Tolerance,
    label: <Trans>Tolerance</Trans>,
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        const otsPart = findComparableOtsPart(part);
        if (
            dialogState.viewMode === 'show-all' &&
            (isResistor({ partType: otsPart.type }) || isCapacitor({ partType: otsPart.type }))
        ) {
            return true;
        }
        const selectedPartTolerance = otsPart.technical_properties?.tolerance
            ? formatTolerance(otsPart.technical_properties?.tolerance)
            : '-';

        return (
            (isResistor({ partType: otsPart.type }) || isCapacitor({ partType: otsPart.type })) &&
            !partAlternatives.every((alternative) => {
                const alternativeTolerance = alternative.technical_properties?.tolerance
                    ? formatTolerance(alternative.technical_properties?.tolerance)
                    : '-';
                return selectedPartTolerance === alternativeTolerance;
            })
        );
    },
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        const tolerance = otsPart.technical_properties?.tolerance;
        return <>{tolerance ? formatTolerance(tolerance) : '-'}</>;
    },
    renderComparableItem: (data) => {
        const tolerance = data.technical_properties?.tolerance;
        return <>{tolerance ? formatTolerance(tolerance) : '-'}</>;
    },
};

const attributePowerRating: PartAttribute = {
    id: AttributesIndex.PowerRating,
    label: <Trans>Power Rating</Trans>,
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        const otsPart = findComparableOtsPart(part);
        if (dialogState.viewMode === 'show-all' && isResistor({ partType: otsPart.type })) {
            return true;
        }
        const selectedPartPowerRating = otsPart.technical_properties?.power_rating
            ? formatPowerRating(otsPart.technical_properties?.power_rating)
            : '-';

        return (
            isResistor({ partType: otsPart.type }) &&
            !partAlternatives.every((alternative) => {
                const alternativePowerRating = alternative.technical_properties?.power_rating
                    ? formatPowerRating(alternative.technical_properties?.power_rating)
                    : '-';
                return selectedPartPowerRating === alternativePowerRating;
            })
        );
    },
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        const powerRating = otsPart.technical_properties?.power_rating;
        return <>{powerRating ? formatPowerRating(powerRating) : '-'}</>;
    },
    renderComparableItem: (data) => {
        const powerRating = data.technical_properties?.power_rating;
        return <>{powerRating ? formatPowerRating(powerRating) : '-'}</>;
    },
};

const attributeDielectric: PartAttribute = {
    id: AttributesIndex.Dielectric,
    label: <Trans>Dielectric</Trans>,
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        const otsPart = findComparableOtsPart(part);
        if (dialogState.viewMode === 'show-all' && isCapacitor({ partType: otsPart.type })) {
            return true;
        }
        const selectedPartDielectric = otsPart.technical_properties?.dielectric
            ? formatDielectricMaterial(otsPart.technical_properties?.dielectric)
            : '-';

        return (
            isCapacitor({ partType: otsPart.type }) &&
            !partAlternatives.every((alternative) => {
                const alternativeDielectric = alternative.technical_properties?.dielectric
                    ? formatDielectricMaterial(alternative.technical_properties?.dielectric)
                    : '-';
                return selectedPartDielectric === alternativeDielectric;
            })
        );
    },
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        const dielectric = otsPart.technical_properties?.dielectric;
        return <>{dielectric ? formatDielectricMaterial(dielectric) : '-'}</>;
    },
    renderComparableItem: (data) => {
        const dielectric = data.technical_properties?.dielectric;
        return <>{dielectric ? formatDielectricMaterial(dielectric) : '-'}</>;
    },
};

const attributeVoltageRating: PartAttribute = {
    id: AttributesIndex.VoltageRating,
    label: <Trans>Voltage rating</Trans>,
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        const otsPart = findComparableOtsPart(part);
        if (
            dialogState.viewMode === 'show-all' &&
            (isResistor({ partType: otsPart.type }) || isCapacitor({ partType: otsPart.type }))
        ) {
            return true;
        }
        const selectedPartVoltageRating = otsPart.technical_properties?.voltage_rating
            ? formatVoltageRating(otsPart.technical_properties?.voltage_rating)
            : '-';

        return (
            (isResistor({ partType: otsPart.type }) || isCapacitor({ partType: otsPart.type })) &&
            !partAlternatives.every((alternative) => {
                const alternativeVoltageRating = alternative.technical_properties?.voltage_rating
                    ? formatVoltageRating(alternative.technical_properties?.voltage_rating)
                    : '-';
                return selectedPartVoltageRating === alternativeVoltageRating;
            })
        );
    },
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        const voltageRating = otsPart.technical_properties?.voltage_rating;
        return <>{voltageRating ? formatVoltageRating(voltageRating) : '-'}</>;
    },
    renderComparableItem: (data) => {
        const voltageRating = data.technical_properties?.voltage_rating;
        return <>{voltageRating ? formatVoltageRating(voltageRating) : '-'}</>;
    },
};

const attributeTempCoefficient: PartAttribute = {
    id: AttributesIndex.TempCoefficient,
    label: <Trans>Temperature coefficient</Trans>,
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        const otsPart = findComparableOtsPart(part);
        if (dialogState.viewMode === 'show-all' && isResistor({ partType: otsPart.type })) {
            return true;
        }
        const selectedPartTempCoefficient = otsPart.technical_properties?.temperature_coefficient
            ? formatTemperatureCoefficient(otsPart.technical_properties?.temperature_coefficient)
            : '-';

        return (
            isResistor({ partType: otsPart.type }) &&
            !partAlternatives.every((alternative) => {
                const alternativeTempCoefficient = alternative.technical_properties?.temperature_coefficient
                    ? formatTemperatureCoefficient(alternative.technical_properties?.temperature_coefficient)
                    : '-';
                return selectedPartTempCoefficient === alternativeTempCoefficient;
            })
        );
    },
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        const temperatureCoefficient = otsPart.technical_properties?.temperature_coefficient;
        return <>{temperatureCoefficient ? formatTemperatureCoefficient(temperatureCoefficient) : '-'}</>;
    },
    renderComparableItem: (data) => {
        const temperatureCoefficient = data.technical_properties?.temperature_coefficient;
        return <>{temperatureCoefficient ? formatTemperatureCoefficient(temperatureCoefficient) : '-'}</>;
    },
};

const packageHeader: PartAttribute = {
    id: AttributesIndex.PackageHeader,
    label: (
        <Text variant="h4">
            <Trans>Package</Trans>
        </Text>
    ),
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        if (dialogState.viewMode === 'show-all') {
            return true;
        }
        const otsPart = findComparableOtsPart(part);
        return !partAlternatives.every(
            (alternative) =>
                alternative.package?.name === otsPart.package?.name &&
                alternative.package?.mounting === otsPart.package?.mounting &&
                alternative.package?.number_of_pins === otsPart.package?.number_of_pins,
        );
    },
    renderItem: () => {
        return <></>;
    },
    renderComparableItem: () => {
        return <></>;
    },
};

const attributePackageName: PartAttribute = {
    id: AttributesIndex.PackageName,
    label: <Trans>Name</Trans>,
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        if (dialogState.viewMode === 'show-all') {
            return true;
        }
        const otsPart = findComparableOtsPart(part);
        return !partAlternatives.every((alternative) => alternative.package?.name === otsPart.package?.name);
    },
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        return <>{otsPart.package?.name ?? '-'}</>;
    },
    renderComparableItem: (data) => {
        const part = data;
        return <>{part.package?.name ?? '-'}</>;
    },
};

const attributeMounting: PartAttribute = {
    id: AttributesIndex.Mounting,
    label: <Trans>Mounting</Trans>,
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        if (dialogState.viewMode === 'show-all') {
            return true;
        }
        const otsPart = findComparableOtsPart(part);
        return !partAlternatives.every((alternative) => alternative.package?.mounting === otsPart.package?.mounting);
    },
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        return <>{otsPart.package?.mounting ?? '-'}</>;
    },
    renderComparableItem: (data) => {
        const part = data;
        return <>{part.package?.mounting ?? '-'}</>;
    },
};

const attributeNumberOfPins: PartAttribute = {
    id: AttributesIndex.NumberOfPins,
    label: <Trans>Number of pins</Trans>,
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        if (dialogState.viewMode === 'show-all') {
            return true;
        }
        const otsPart = findComparableOtsPart(part);
        return !partAlternatives.every(
            (alternative) => alternative.package?.number_of_pins === otsPart.package?.number_of_pins,
        );
    },
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        return <>{otsPart.package?.number_of_pins ?? '-'}</>;
    },
    renderComparableItem: (data) => {
        const part = data;
        return <>{part.package?.number_of_pins ?? '-'}</>;
    },
};

const complianceHeader: PartAttribute = {
    id: AttributesIndex.ComplianceHeader,
    label: (
        <Text variant="h4">
            <Trans>Compliance</Trans>
        </Text>
    ),
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }, { assemblyIndustry }) => {
        if (dialogState.viewMode === 'show-all') {
            return true;
        }
        const otsPart = findComparableOtsPart(part);
        if (assemblyIndustry === 'Auto') {
            return !partAlternatives.every(
                (alternative) =>
                    alternative.rohs_compliant === otsPart.rohs_compliant &&
                    alternative.reach_compliant === otsPart.reach_compliant &&
                    alternative.aecq_compliant === otsPart.aecq_compliant,
            );
        }
        return !partAlternatives.every(
            (alternative) =>
                alternative.rohs_compliant === otsPart.rohs_compliant &&
                alternative.reach_compliant === otsPart.reach_compliant,
        );
    },
    renderItem: () => {
        return <></>;
    },
    renderComparableItem: () => {
        return <></>;
    },
};

const attributeRohsCompliant: PartAttribute = {
    id: AttributesIndex.RohsCompliant,
    label: <Trans>RoHS</Trans>,
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        if (dialogState.viewMode === 'show-all') {
            return true;
        }
        const otsPart = findComparableOtsPart(part);
        return !partAlternatives.every((alternative) => alternative.rohs_compliant === otsPart.rohs_compliant);
    },
    renderItem: ({ data: part }) => {
        return (
            <OtsPartComplianceStatusOriginsTooltip partId={part.id} name="RoHS" complianceStatus={part.rohs_compliant}>
                <ComplianceStatusChip status={part.rohs_compliant} />
            </OtsPartComplianceStatusOriginsTooltip>
        );
    },
    renderComparableItem: (data) => {
        const part = data;
        return (
            <OtsPartComplianceStatusOriginsTooltip partId={part.id} name="RoHS" complianceStatus={part.rohs_compliant}>
                <ComplianceStatusChip status={part.rohs_compliant} />
            </OtsPartComplianceStatusOriginsTooltip>
        );
    },
};

const attributeReachCompliant: PartAttribute = {
    id: AttributesIndex.ReachCompliant,
    label: <Trans>REACH</Trans>,
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        if (dialogState.viewMode === 'show-all') {
            return true;
        }
        const otsPart = findComparableOtsPart(part);
        return !partAlternatives.every((alternative) => alternative.reach_compliant === otsPart.reach_compliant);
    },
    renderItem: ({ data: part }) => {
        return (
            <OtsPartComplianceStatusOriginsTooltip
                partId={part.id}
                name="REACH"
                complianceStatus={part.reach_compliant}
            >
                <ComplianceStatusChip status={part.reach_compliant} />
            </OtsPartComplianceStatusOriginsTooltip>
        );
    },
    renderComparableItem: (data) => {
        const part = data;
        return (
            <OtsPartComplianceStatusOriginsTooltip
                partId={part.id}
                name="REACH"
                complianceStatus={part.reach_compliant}
            >
                <ComplianceStatusChip status={part.reach_compliant} />
            </OtsPartComplianceStatusOriginsTooltip>
        );
    },
};

const attributeAecqCompliant: PartAttribute = {
    id: AttributesIndex.AecqCompliant,
    label: <Trans>AECQ</Trans>,
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }, { assemblyIndustry }) => {
        if (assemblyIndustry !== 'Auto') {
            return false;
        }
        if (dialogState.viewMode === 'show-all') {
            return true;
        }
        const otsPart = findComparableOtsPart(part);
        return !partAlternatives.every((alternative) => alternative.aecq_compliant === otsPart.aecq_compliant);
    },
    renderItem: ({ data: part }) => {
        return <ComplianceStatusChip status={part.aecq_compliant} />;
    },
    renderComparableItem: (data) => {
        const part = data;
        return <ComplianceStatusChip status={part.aecq_compliant} />;
    },
};

const lifecycleHeader: PartAttribute = {
    id: AttributesIndex.LifecycleHeader,
    label: (
        <Text variant="h4">
            <Trans>Lifecycle</Trans>
        </Text>
    ),
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        if (dialogState.viewMode === 'show-all') {
            return true;
        }
        const otsPart = findComparableOtsPart(part);
        return !partAlternatives.every(
            (alternative) =>
                alternative.lifecycle_status === otsPart.lifecycle_status &&
                alternative.lifecycle_yteol === otsPart.lifecycle_yteol,
        );
    },
    renderItem: () => {
        return <></>;
    },
    renderComparableItem: () => {
        return <></>;
    },
};

const attributelifecycle: PartAttribute = {
    id: AttributesIndex.Lifecycle,
    label: (
        <Flexbox alignItems="center">
            <Trans>Status</Trans>
            <LifecycleTooltip>
                <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
            </LifecycleTooltip>
        </Flexbox>
    ),
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        if (dialogState.viewMode === 'show-all') {
            return true;
        }
        const otsPart = findComparableOtsPart(part);
        return !partAlternatives.every((alternative) => alternative.lifecycle_status === otsPart.lifecycle_status);
    },
    renderItem: ({ data: part }) => {
        return (
            <OtsPartLifecycleStatusOriginsTooltip partId={part.id} lifecycleStatus={part.lifecycle_status}>
                <LifecycleChip lifecycle={part.lifecycle_status} />
            </OtsPartLifecycleStatusOriginsTooltip>
        );
    },
    renderComparableItem: (data) => {
        const part = data;
        return (
            <OtsPartLifecycleStatusOriginsTooltip partId={part.id} lifecycleStatus={part.lifecycle_status}>
                <LifecycleChip lifecycle={part.lifecycle_status} />
            </OtsPartLifecycleStatusOriginsTooltip>
        );
    },
};

const attributeLifecycleYteol: PartAttribute = {
    id: AttributesIndex.LifecycleYteol,
    label: <Trans>Years-to-EOL</Trans>,
    isVisible: ({ data: part, comparableData: partAlternatives, dialogState }) => {
        if (dialogState.viewMode === 'show-all') {
            return true;
        }
        const otsPart = findComparableOtsPart(part);
        return !partAlternatives.every((alternative) => alternative.lifecycle_yteol === otsPart.lifecycle_yteol);
    },
    renderItem: ({ data: part }) => {
        const otsPart = findComparableOtsPart(part);
        return <>{otsPart.lifecycle_yteol ?? '-'}</>;
    },
    renderComparableItem: (data) => {
        const part = data;
        return <>{part.lifecycle_yteol ?? '-'}</>;
    },
};

const sustainabilityHeader: PartAttribute = {
    id: AttributesIndex.SustainabilityHeader,
    label: (
        <Text variant="h4">
            <Trans>Sustainability</Trans>
        </Text>
    ),
    isVisible: () => true,
    renderItem: () => {
        return <></>;
    },
    renderComparableItem: () => {
        return <></>;
    },
};

const attributeEmissions: PartAttribute = {
    id: AttributesIndex.Emissions,
    label: (
        <Flexbox alignItems="center">
            <Trans>Emissions</Trans>
            <EmissionsTooltip>
                <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
            </EmissionsTooltip>
        </Flexbox>
    ),
    isVisible: () => true,
    renderItem: ({ data: part }) => {
        return <PartEmissionsView part={part} />;
    },
    renderComparableItem: (data) => {
        const part = data;
        return <PartEmissionsView part={part} />;
    },
};

export const EmsAttributes = [
    attributeImage,
    attributeMpnManufacturer,
    attributeDescription,
    attributeDatasheet,
    attributePartType,
    sourcingHeader,
    attributeAvailability,
    attributeBestPriceInStock,
    attributeBestPrice,
    specificationsHeader,
    attributeResistance,
    attributeCapacitance,
    attributeTolerance,
    attributePowerRating,
    attributeDielectric,
    attributeVoltageRating,
    attributeTempCoefficient,
    packageHeader,
    attributePackageName,
    attributeMounting,
    attributeNumberOfPins,
    complianceHeader,
    attributeRohsCompliant,
    attributeReachCompliant,
    attributeAecqCompliant,
    lifecycleHeader,
    attributelifecycle,
    attributeLifecycleYteol,
    sustainabilityHeader,
    attributeEmissions,
];

export const CustomerAttributes = EmsAttributes.filter(
    (attribute) => attribute.id !== AttributesIndex.BestPriceInStock && attribute.id !== AttributesIndex.BestPrice,
);
