import { Trans } from '@lingui/macro';
import { Flexbox, Text, Tooltip, TooltipProps } from '@luminovo/design-system';
import { ComplianceStatus, OffTheShelfPartVote } from '@luminovo/http-client';
import { ComplianceChip } from '@luminovo/sourcing-core';
import { Skeleton } from '@mui/material';
import { useCallback, useState } from 'react';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';

export const OtsPartComplianceStatusOriginsTooltip: React.FunctionComponent<{
    partId: string;
    name: 'RoHS' | 'REACH';
    complianceStatus: ComplianceStatus;
    children: TooltipProps['children'];
}> = ({ partId, name, complianceStatus, children }): JSX.Element => {
    const [votes, setVotes] = useState<OffTheShelfPartVote[] | undefined>();

    const { mutateAsync: fetchVotes, isLoading } = useHttpMutation('GET /parts/off-the-shelf/:partId/votes', {
        snackbarMessage: null,
    });

    const handleOpen = useCallback(async () => {
        if (complianceStatus === ComplianceStatus.Unknown) return;
        if (!votes) {
            const response = await fetchVotes({ pathParams: { partId } });
            setVotes(response.items);
        }
    }, [votes, fetchVotes, partId, complianceStatus]);

    if (isLoading) {
        return (
            <Tooltip
                arrow
                title={
                    <Flexbox flexDirection="column" gap="16px" width="200px" padding="4px">
                        <Text variant="h5">
                            <Trans>{name} origins</Trans>
                        </Text>
                        <Skeleton width="75%" />
                    </Flexbox>
                }
                variant="white"
            >
                <span>{children}</span>
            </Tooltip>
        );
    }

    const showTooltip =
        votes &&
        votes.length > 0 &&
        votes.some((origin) => {
            if (name === 'REACH') {
                return !!origin.reach_compliant;
            }
            if (name === 'RoHS') {
                return !!origin.rohs_compliant;
            }
            return false;
        });

    return (
        <Tooltip
            arrow
            onOpen={handleOpen}
            title={
                showTooltip ? (
                    <Flexbox flexDirection="column" gap="16px" width="220px" padding="4px">
                        <Text variant="h5">
                            <Trans>{name} origins</Trans>
                        </Text>
                        <Flexbox flexDirection="column" gap="8px">
                            {votes.map((origin, i) => {
                                const complianceOrigin =
                                    name === 'REACH' ? origin.reach_compliant : origin.rohs_compliant;

                                if (!complianceOrigin) {
                                    return null;
                                }
                                return (
                                    <Flexbox key={i} alignItems="center" justifyContent="space-between" gap="4px">
                                        <ComplianceChip
                                            complianceStatus={complianceOrigin}
                                            name={name}
                                            disabledTooltip={true}
                                        />
                                        <Text variant="body-small" showEllipsis>
                                            {origin.origin.type}
                                        </Text>
                                    </Flexbox>
                                );
                            })}
                        </Flexbox>
                    </Flexbox>
                ) : (
                    ''
                )
            }
            variant="white"
        >
            <span>{children}</span>
        </Tooltip>
    );
};
