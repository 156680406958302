import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Flexbox, StatusChip, Tooltip, colorSystem } from '@luminovo/design-system';
import { ComplianceStatus } from '@luminovo/http-client';

interface ComplianceLabel {
    color: keyof typeof colorSystem;
    label: string;
    tooltip?: string;
}

export const ComplianceOverviewChips = ({
    reach,
    rohs,
    aecq,
    disabled = false,
}: {
    reach?: ComplianceStatus;
    rohs?: ComplianceStatus;
    aecq?: ComplianceStatus;
    disabled?: boolean;
}): JSX.Element => {
    return (
        <Flexbox gap={4}>
            {reach && <ComplianceChip complianceStatus={reach} name="REACH" disabled={disabled} />}
            {rohs && <ComplianceChip complianceStatus={rohs} name="RoHS" disabled={disabled} />}
            {aecq && <ComplianceChip complianceStatus={aecq} name="AECQ" disabled={disabled} />}
        </Flexbox>
    );
};

export const ComplianceChip = ({
    complianceStatus,
    name,
    disabled = false,
    disabledTooltip = false,
}: {
    complianceStatus: ComplianceStatus;
    name: 'RoHS' | 'REACH' | 'AECQ';
    disabled?: boolean;
    disabledTooltip?: boolean;
}): JSX.Element => {
    const { color, label, tooltip } = getComplianceDetails({ complianceStatus, name });

    return (
        <Tooltip title={disabledTooltip ? '' : tooltip ?? ''}>
            <span>
                <StatusChip color={color} label={label} disabled={disabled} enableHoverEffect={Boolean(tooltip)} />
            </span>
        </Tooltip>
    );
};

const getComplianceDetails = ({
    complianceStatus,
    name,
}: {
    complianceStatus: ComplianceStatus;
    name: 'RoHS' | 'REACH' | 'AECQ';
}): ComplianceLabel => {
    switch (complianceStatus) {
        case ComplianceStatus.Compliant:
            return { color: 'green', label: name, tooltip: t`${name} compliant` };
        case ComplianceStatus.CompliantWithExemption:
            return {
                color: 'green',
                label: name,
                tooltip: t`${name} compliant with exemption`,
            };
        case ComplianceStatus.NonCompliant:
            return { color: 'red', label: name, tooltip: t`Not ${name} compliant` };
        case ComplianceStatus.NotRequired:
            return { color: 'neutral', label: name, tooltip: t`${name} compliance not required` };
        case ComplianceStatus.Unknown:
            return { color: 'neutral', label: name, tooltip: t`${name} compliance unknown` };
        default:
            assertUnreachable(complianceStatus);
    }
};
